const loginReducer = (
  state = {
    permissions: {},
    resources: {},
  },
  action
) => {
  switch (action.type) {
    case 'SET_PERMISSIONS_AND_RESOURCES': {
      console.log('SET_PERMISSIONS_AND_RESOURCES')
      return {
        ...state,
        permissions: action.payload.permissions,
        resources: action.payload.resources,
        locationMap: action.payload.locationMap,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
export default loginReducer
