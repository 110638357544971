import React from 'react'
import { useNavigate } from 'react-router-dom'

const Reconciliation = () => {
  const navigate = useNavigate()
  return (
    <div style={{ padding: '32px', width: '100%' }}>
      <div
        style={{
          fontSize: '28px',
          fontWeight: '700',
          lineHeight: '32px',
          color: '#575757',
        }}
      >
        Reconciliation
      </div>
      <div
        style={{
          marginTop: '40px',
          display: 'flex',
          flexDirection: 'row',
          columnGap: '16px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/createBranchPayment')}
        >
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '128px',
              padding: '24px 16px',
              borderRadius: '8px',
              borderColor: '1px solid #9c27b0',
              background: 'rgba(156,39,76,0.08)',
              width: '360px',
            }}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#9c27b0"
                  d="M18 12c-.55 0-1 .45-1 1v5.22c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h5c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1m3.02-7H19V2.98c0-.54-.44-.98-.98-.98h-.03c-.55 0-.99.44-.99.98V5h-2.01c-.54 0-.98.44-.99.98v.03c0 .55.44.99.99.99H17v2.01c0 .54.44.99.99.98h.03c.54 0 .98-.44.98-.98V7h2.02c.54 0 .98-.44.98-.98v-.04c0-.54-.44-.98-.98-.98"
                />
                <path
                  fill="#9c27b0"
                  d="M14 9H8c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1m0 3H8c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1m0 3H8c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1"
                />
              </svg>
            </div>
            <div
              style={{
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#9c27b0',
                marginTop: '8px',
              }}
            >
              Upload Bank Statement
            </div>
          </div>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/trackreconciliation')}
        >
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '128px',
              padding: '24px 16px',
              borderRadius: '8px',
              borderColor: '1px solid #9c27b0',
              background: 'rgba(39, 176, 108, 0.08)',
              width: '360px',
            }}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 16 16"
              >
                <path
                  fill="none"
                  stroke="#27b06b"
                  stroke-linejoin="round"
                  d="M7.563 1.545H2.5v10.91h9V5.364M7.563 1.545L11.5 5.364M7.563 1.545v3.819H11.5m-7 9.136h9v-7M4 7.5h6M4 5h2m-2 5h6"
                />
              </svg>
            </div>
            <div
              style={{
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#27b06b',
                marginTop: '8px',
              }}
            >
              Track Reconciliation
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reconciliation
