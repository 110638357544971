import React, { Component, lazy, Suspense, useEffect } from 'react'
import './App.scss'
import { connect } from 'react-redux'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Link,
  Navigate,
} from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { getUserPermissions } from './actions/apiActions'
import Reconciliation from './components/Reconciliation/Reconciliation'
import TrackReconciliation from './components/TrackReconciliation/TrackReconciliation'
const Panel = lazy(() => import('./components/Panel/Panel'))
const CreateBranchPayment = lazy(
  () => import('./components/CreateBranchPayment/CreateBranchPayment')
)
const CreateRefund = lazy(() => import('./components/Refund/CreateRefund'))
const NSDCVerification = lazy(
  () => import('./components/NSDC/NSDCVerification')
)
const Reports = lazy(() => import('./components/Reports/Reports'))
// import Panel from './components/Panel/Panel'
// import CreateBranchPayment from './components/CreateBranchPayment/CreateBranchPayment'

function App(props) {
  console.log({ props })
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    // execute on location change
    console.log('Location changed!', location.pathname)
    let listener = async () => {
      let pathname = location.pathname
      try {
        let res = await getUserPermissions()
        console.log({ res })
        if (res.success) {
          // console.log({
          //   permissions: this.state.permissions,
          //   resourceList: this.state.resourceList,
          // })
          props.setPermissions(
            res.data.permissionMap,
            res.data.resources,
            res.data.locationMap
          )
        } else {
          alert(res.message || 'Something went wrong. Try Logging in again.')
          if (pathname != '/') {
            navigate('/')
          }
        }
      } catch (err) {
        alert(err?.message || 'Something went wrong. Try Logging in again.')
        if (pathname != '/') {
          navigate('/')
        }
      }
    }
    listener()
  }, [location])

  let checkVisibility = (resource: string | number) => {
    console.log('checkVisibility', { resource })
    if (!props.permissions) {
      return true
    } else {
      console.log({
        'props.permissions[resource]': props.permissions[resource],
      })
      if (!props.permissions[resource]) {
        return false
      } else if (props.permissions[resource].indexOf(1) != -1) {
        return true
      } else {
        return false
      }
    }
  }
  return (
    <>
      <Suspense
        fallback={
          <div className="Loader">
            <CircularProgress />
          </div>
        }
      >
        <div
          style={{
            margin: 0,
            padding: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignContent: 'stretch',
            // width: '100vw',
            height: '100vh',
            paddingLeft: '200px',
          }}
        >
          <div className="sidenav">
            <p className="companyname" style={{ overflowX: 'hidden' }}>
              {/* <a className='link' href="/">IMARTICUS</a> */}
              <Link to="/">
                <img
                  src="https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
                  alt="logo"
                  className="as-dl-logo-hp"
                />
              </Link>
            </p>
          </div>
          <Routes>
            <Route path="/">
              <Route index={true} element={<Panel />} />
              <Route path="reconciliation" element={<Reconciliation />} />
              <Route
                path="createBranchPayment"
                element={<CreateBranchPayment />}
              />
              <Route
                path="trackreconciliation"
                element={<TrackReconciliation />}
              />
              <Route path="refundPayment" element={<CreateRefund />} />
              <Route path="nsdcVerification" element={<NSDCVerification />} />
              <Route path="reports" element={<Reports />} />
            </Route>
          </Routes>
        </div>
      </Suspense>
    </>
  )
}

const mapStateToProps = (state) => {
  // console.log({state})
  return {
    permissions: state.permissions,
    resources: state.resources,
  }
}
const mapDispatchToProps = {
  setPermissions: (permissions, resources, locationMap) => (dispatch) => {
    return dispatch({
      type: 'SET_PERMISSIONS_AND_RESOURCES',
      payload: { permissions, resources, locationMap },
    })
  },
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
